@charset "UTF-8";
/* Css link*/
/* 1. 기본 컬러 세팅 - form, layout, commons에 사용 */
/* 2. 값 세팅 - form, layout, commons, content에 사용  */
/* 3. 자주 쓰는 문법  */
.color-brand {
  color: #3e66fb; }

.color-secondary {
  color: #3e66fb; }

.color-success {
  color: #3ac062; }

.color-info {
  color: #3bc1ff; }

.color-warning {
  color: #ffc107; }

.color-danger {
  color: #ff3333; }

.color-lightgray {
  color: #dcd9e1; }

.color-gray {
  color: #a8a7ac; }

.color-darkgray {
  color: #434245; }

.color-white {
  color: white; }

.color-black {
  color: black; }

.bg-brand {
  background-color: #3e66fb; }
  .bg-brand.bg-light {
    background-color: #d8e0fe; }

.bg-secondary {
  background-color: #3e66fb; }
  .bg-secondary.bg-light {
    background-color: #d8e0fe; }

.bg-success {
  background-color: #3ac062; }
  .bg-success.bg-light {
    background-color: #d8f2e0; }

.bg-info {
  background-color: #3bc1ff; }
  .bg-info.bg-light {
    background-color: #d8f3ff; }

.bg-warning {
  background-color: #ffc107; }
  .bg-warning.bg-light {
    background-color: #fff3cd; }

.bg-danger {
  background-color: #ff3333; }
  .bg-danger.bg-light {
    background-color: #ffd6d6; }

.bg-gray {
  background-color: #a8a7ac; }
  .bg-gray.bg-light {
    background-color: #cac8cf; }

.bg-lightgray {
  background-color: #dcd9e1; }
  .bg-lightgray.bg-light {
    background-color: #eceaf2; }

.bg-darkgray {
  background-color: #434245; }
  .bg-darkgray.bg-light {
    background-color: #656467; }

/* 3. 자주 쓰는 문법  */
.ws-none {
  white-space: nowrap; }

.scale-1 {
  transform: scale(1.2); }

.scale-2 {
  transform: scale(1.5); }

.scale-3 {
  transform: scale(2); }

.scale-4 {
  transform: scale(3); }

.scale-5 {
  transform: scale(5); }

.tr {
  text-align: right; }

.tl {
  text-align: left; }

.tc {
  text-align: center; }

.fr {
  float: right; }

.fl {
  float: left; }

.flx-1 {
  flex: 1; }

.flx-shrink-0 {
  flex-shrink: 0; }

.flx-full > * {
  width: 100%; }

.flx-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center; }
  .flx-row.gap-0 {
    gap: 0; }
  .flx-row.gap-1 {
    gap: 0.5rem; }
  .flx-row.gap-2 {
    gap: 1rem; }
  .flx-row.gap-3 {
    gap: 2rem; }
  .flx-row.row-top {
    align-items: unset; }

.flx-col {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 0.5rem; }
  .flx-col.gap-0 {
    gap: 0; }
  .flx-col.gap-1 {
    gap: 0.5rem; }
  .flx-col.gap-2 {
    gap: 1rem; }
  .flx-col.gap-3 {
    gap: 2rem; }

.flx-sb {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .flx-sb.flx-top {
    align-items: flex-start; }

.flx-right {
  justify-content: flex-end !important; }

.flx-left {
  justify-content: flex-start !important; }

.flx-center {
  justify-content: center !important; }

.flx-bottom {
  align-content: flex-end !important;
  align-items: flex-end !important; }

.flx-top {
  align-content: flex-start !important;
  align-items: flex-start !important; }

.w-10 {
  width: 10% !important; }

.w-20 {
  width: 20% !important; }

.w-30 {
  width: 30% !important; }

.w-40 {
  width: 40% !important; }

.w-50 {
  width: 50% !important; }

.w-60 {
  width: 60% !important; }

.w-70 {
  width: 70% !important; }

.w-80 {
  width: 80% !important; }

.w-90 {
  width: 90% !important; }

.w-100 {
  width: 100% !important; }

.h-10 {
  height: 10% !important; }

.h-20 {
  height: 20% !important; }

.h-30 {
  height: 30% !important; }

.h-40 {
  height: 40% !important; }

.h-50 {
  height: 50% !important; }

.h-60 {
  height: 60% !important; }

.h-70 {
  height: 70% !important; }

.h-80 {
  height: 80% !important; }

.h-90 {
  height: 90% !important; }

.h-100 {
  height: 100% !important; }

.h-100-m {
  height: calc(100% - 1rem); }

.br-0 {
  border-radius: 0px; }

.br-1 {
  border-radius: 0.5rem; }

.br-2 {
  border-radius: 1rem; }

.br-3 {
  border-radius: 2rem; }

.br-4 {
  border-radius: 3rem; }

.br-5 {
  border-radius: 100rem; }

.min-w-none {
  min-width: unset !important; }

.max-w-none {
  max-width: unset !important; }

.min-h-none {
  min-height: unset !important; }

.max-h-none {
  max-height: unset !important; }

.show__under-mobile {
  display: none !important; }
  @media (max-width: 570px) {
    .show__under-mobile {
      display: block !important; } }

.show__under-tablet {
  display: none !important; }
  @media (max-width: 758px) {
    .show__under-tablet {
      display: block !important; } }

.show__under-desktop {
  display: none !important; }
  @media (max-width: 1200px) {
    .show__under-desktop {
      display: block !important; } }

.show__only-labtop {
  display: none !important; }
  @media (min-width: 1024px) and (max-width: 1700px) {
    .show__only-labtop {
      display: block !important; } }

.show__over-mobile {
  display: block !important; }
  @media (max-width: 570px) {
    .show__over-mobile {
      display: none !important; } }

.mini-box {
  border-radius: 5px;
  box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18);
  padding: 1rem;
  background-color: white; }

.shadow-none {
  box-shadow: none !important; }

.shadow-big {
  box-shadow: 0rem 0.47rem 2.19rem rgba(8, 10, 37, 0.03), 0rem 0.94rem 1.41rem rgba(8, 10, 37, 0.03), 0rem 0.25rem 0.53rem rgba(8, 10, 37, 0.05), 0rem 0.13rem 0.19rem rgba(8, 10, 37, 0.03) !important; }

.shadow-small {
  box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18) !important; }

body {
  overflow: hidden !important; }

.app-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  margin: 0; }
  .app-container .app-header {
    position: fixed;
    width: 100%;
    top: 0;
    height: 60px;
    display: flex;
    align-items: center;
    align-content: center;
    z-index: 10;
    transition: all .2s;
    background-color: #FAFAFC;
    box-shadow: 0 0 1rem rgba(30, 10, 10, 0.15); }
    .app-container .app-header .app-header__logo {
      width: 280px;
      height: 60px;
      padding: 0 1.5rem;
      padding-right: 1.4rem;
      display: flex;
      align-items: center;
      transition: width .2s; }
      .app-container .app-header .app-header__logo .logo-src {
        background-image: none;
        white-space: nowrap;
        line-height: auto;
        font-size: 1.2rem;
        font-weight: 700;
        letter-spacing: -1px;
        color: #3e66fb !important;
        margin-top: -5px; }
    .app-container .app-header .app-header__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      flex: 1;
      padding: 0 1.5rem;
      height: 60px; }
      .app-container .app-header .app-header__content .app-header-left, .app-container .app-header .app-header__content .app-header-right {
        display: flex;
        align-items: center;
        gap: 0; }
        .app-container .app-header .app-header__content .app-header-left .menu-sub, .app-container .app-header .app-header__content .app-header-right .menu-sub {
          color: rgba(0, 0, 0, 0.35);
          line-height: auto;
          font-size: 0.7rem;
          font-weight: 400; }
        .app-container .app-header .app-header__content .app-header-left .menu-main, .app-container .app-header .app-header__content .app-header-right .menu-main {
          color: #3252c9;
          line-height: auto;
          font-size: 0.9rem;
          font-weight: 700; }
        .app-container .app-header .app-header__content .app-header-left .icon-next, .app-container .app-header .app-header__content .app-header-right .icon-next {
          opacity: .4;
          margin-right: 6px;
          transform: scale(0.5); }
    @media (max-width: 570px) {
      .app-container .app-header {
        background: linear-gradient(45deg, #1f1d1d, #2b282e); }
        .app-container .app-header .app-header__content {
          background-color: white; }
          .app-container .app-header .app-header__content .app-header-left {
            display: none !important; }
          .app-container .app-header .app-header__content .app-header-right {
            margin: 0 auto;
            gap: .5rem; }
            .app-container .app-header .app-header__content .app-header-right .header-btn-lg::before {
              display: none; } }
  .app-container .app-main {
    padding-top: 60px;
    z-index: 8;
    position: relative;
    background: #edebf3; }
    .app-container .app-main .app-main__outer {
      display: flex;
      flex-direction: column;
      flex: 1 1;
      padding-left: 280px;
      z-index: 12;
      height: calc(100vh - 60px); }
      .app-container .app-main .app-main__outer .app-main__inner {
        padding: 0 !important;
        flex: 1 1 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        background: #edebf3;
        overflow: auto; }
        .app-container .app-main .app-main__outer .app-main__inner .app-page-title {
          display: flex;
          flex-direction: column;
          text-align: left;
          background: rgba(255, 255, 255, 0.55);
          padding: 1.5rem;
          height: 100px;
          margin: 0; }
          .app-container .app-main .app-main__outer .app-main__inner .app-page-title .menu-sub {
            line-height: 1.2;
            font-size: 0.8rem;
            font-weight: 400; }
          .app-container .app-main .app-main__outer .app-main__inner .app-page-title .menu-main {
            line-height: auto;
            font-size: 1.5rem;
            font-weight: 700;
            color: #3252c9; }
          @media (max-width: 570px) {
            .app-container .app-main .app-main__outer .app-main__inner .app-page-title {
              height: 80px;
              align-items: center;
              justify-content: center;
              gap: .5rem;
              padding: 1.5rem;
              justify-content: center;
              flex-direction: row; } }
        .app-container .app-main .app-main__outer .app-main__inner .app-page-content {
          padding: 1.5rem 1.5rem 2.5rem 1.5rem;
          box-sizing: border-box;
          position: relative;
          display: flex;
          flex-direction: column; }
          @media (max-width: 570px) {
            .app-container .app-main .app-main__outer .app-main__inner .app-page-content {
              padding: 15px; } }
        .app-container .app-main .app-main__outer .app-main__inner .dashboard-edit {
          position: fixed;
          bottom: 2rem;
          right: 1.5rem; }
    .app-container .app-main .app-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      z-index: 12;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #FAFAFC;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      height: 30px;
      padding-right: 1rem; }
      .app-container .app-main .app-footer .footer-wrap {
        line-height: auto;
        font-size: 0.7rem;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.35);
        opacity: .5; }

*::-webkit-scrollbar {
  width: 8px;
  height: 8px; }

*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-clip: padding-box;
  border: 1px solid transparent; }

*::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px; }

.app-theme-pnt-navy .app-sidebar.nav-box__img-bg::before {
  z-index: -1;
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(93, 89, 100, 0.8)); }

.app-theme-pnt-navy .app-sidebar.nav-box__img-bg * {
  color: white; }

.header-app {
  height: 38px; }
  .header-app .pnt-select--group .select__btn {
    border-radius: 100rem;
    border: none !important;
    background-color: transparent !important; }
    .header-app .pnt-select--group .select__btn .cont-wrap {
      line-height: auto;
      font-size: 0.8rem;
      font-weight: 700; }
  .header-app .pnt-select--group.on .select__btn {
    filter: brightness(0.8); }
  .header-app.theme-box > div {
    width: 7.5rem;
    min-width: unset; }
  .header-app.user-box > div {
    width: 10rem; }
  .header-app.theme-box {
    box-shadow: 0rem 0.47rem 2.19rem rgba(8, 10, 37, 0.03), 0rem 0.94rem 1.41rem rgba(8, 10, 37, 0.03), 0rem 0.25rem 0.53rem rgba(8, 10, 37, 0.05), 0rem 0.13rem 0.19rem rgba(8, 10, 37, 0.03);
    border-radius: 100rem; }
  .header-app.user-box {
    position: relative;
    padding-left: .5rem; }
    .header-app.user-box .pnt-select--group .select__btn .cont-wrap {
      padding-left: 2.3rem; }
      .header-app.user-box .pnt-select--group .select__btn .cont-wrap span {
        transform: scale(1.5) translateY(-30%) translateX(12%); }
      .header-app.user-box .pnt-select--group .select__btn .cont-wrap p {
        line-height: 1;
        font-size: 0.7rem;
        font-weight: 200;
        opacity: .6;
        padding: 0;
        margin: 0; }
    .header-app.user-box::before {
      content: '';
      width: 2px;
      height: 20px;
      display: block;
      border-radius: 100px;
      background-color: rgba(0, 0, 0, 0.1);
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%); }

.pnt-light {
  background-color: #ebebeb; }

.pnt-dark {
  background-color: #212529; }

.pnt-navy {
  background-color: #6748E3; }

.pnt-plant {
  background-color: #b3e474; }

.pnt-flower {
  background-color: #ff99ac; }
