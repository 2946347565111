// Datepicker

$datepicker__background-color: $white;
$datepicker__border-color: $gray-300;
$datepicker__highlighted-color: $primary;
$datepicker__muted-color: $gray-400;
$datepicker__selected-color: theme-color('primary');
$datepicker__text-color: $gray-700;
$datepicker__header-color: $gray-800;
$datepicker__navigation-disabled-color: darken($datepicker__muted-color, 5%);

$datepicker__border-radius: $border-radius;
$datepicker__day-margin: 0.166rem;
$datepicker__font-size: $font-size-base;
$datepicker__font-family: $font-size-base;
$datepicker__item-size: 1.7rem;
$datepicker__margin: 0.4rem;
$datepicker__navigation-size: 0.45rem;
$datepicker__triangle-size: 6px;
