@charset "UTF-8";
/* Css link*/
/* 1. 기본 컬러 세팅 - form, layout, commons에 사용 */
/* 2. 값 세팅 - form, layout, commons, content에 사용  */
/* 3. 자주 쓰는 문법  */
.color-brand {
  color: #3e66fb; }

.color-secondary {
  color: #3e66fb; }

.color-success {
  color: #3ac062; }

.color-info {
  color: #3bc1ff; }

.color-warning {
  color: #ffc107; }

.color-danger {
  color: #ff3333; }

.color-lightgray {
  color: #dcd9e1; }

.color-gray {
  color: #a8a7ac; }

.color-darkgray {
  color: #434245; }

.color-white {
  color: white; }

.color-black {
  color: black; }

.bg-brand {
  background-color: #3e66fb; }
  .bg-brand.bg-light {
    background-color: #d8e0fe; }

.bg-secondary {
  background-color: #3e66fb; }
  .bg-secondary.bg-light {
    background-color: #d8e0fe; }

.bg-success {
  background-color: #3ac062; }
  .bg-success.bg-light {
    background-color: #d8f2e0; }

.bg-info {
  background-color: #3bc1ff; }
  .bg-info.bg-light {
    background-color: #d8f3ff; }

.bg-warning {
  background-color: #ffc107; }
  .bg-warning.bg-light {
    background-color: #fff3cd; }

.bg-danger {
  background-color: #ff3333; }
  .bg-danger.bg-light {
    background-color: #ffd6d6; }

.bg-gray {
  background-color: #a8a7ac; }
  .bg-gray.bg-light {
    background-color: #cac8cf; }

.bg-lightgray {
  background-color: #dcd9e1; }
  .bg-lightgray.bg-light {
    background-color: #eceaf2; }

.bg-darkgray {
  background-color: #434245; }
  .bg-darkgray.bg-light {
    background-color: #656467; }

/* 3. 자주 쓰는 문법  */
.ws-none {
  white-space: nowrap; }

.scale-1 {
  transform: scale(1.2); }

.scale-2 {
  transform: scale(1.5); }

.scale-3 {
  transform: scale(2); }

.scale-4 {
  transform: scale(3); }

.scale-5 {
  transform: scale(5); }

.tr {
  text-align: right; }

.tl {
  text-align: left; }

.tc {
  text-align: center; }

.fr {
  float: right; }

.fl {
  float: left; }

.flx-1 {
  flex: 1; }

.flx-shrink-0 {
  flex-shrink: 0; }

.flx-full > * {
  width: 100%; }

.flx-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center; }
  .flx-row.gap-0 {
    gap: 0; }
  .flx-row.gap-1 {
    gap: 0.5rem; }
  .flx-row.gap-2 {
    gap: 1rem; }
  .flx-row.gap-3 {
    gap: 2rem; }
  .flx-row.row-top {
    align-items: unset; }

.flx-col {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 0.5rem; }
  .flx-col.gap-0 {
    gap: 0; }
  .flx-col.gap-1 {
    gap: 0.5rem; }
  .flx-col.gap-2 {
    gap: 1rem; }
  .flx-col.gap-3 {
    gap: 2rem; }

.flx-sb {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .flx-sb.flx-top {
    align-items: flex-start; }

.flx-right {
  justify-content: flex-end !important; }

.flx-left {
  justify-content: flex-start !important; }

.flx-center {
  justify-content: center !important; }

.flx-bottom {
  align-content: flex-end !important;
  align-items: flex-end !important; }

.flx-top {
  align-content: flex-start !important;
  align-items: flex-start !important; }

.w-10 {
  width: 10% !important; }

.w-20 {
  width: 20% !important; }

.w-30 {
  width: 30% !important; }

.w-40 {
  width: 40% !important; }

.w-50 {
  width: 50% !important; }

.w-60 {
  width: 60% !important; }

.w-70 {
  width: 70% !important; }

.w-80 {
  width: 80% !important; }

.w-90 {
  width: 90% !important; }

.w-100 {
  width: 100% !important; }

.h-10 {
  height: 10% !important; }

.h-20 {
  height: 20% !important; }

.h-30 {
  height: 30% !important; }

.h-40 {
  height: 40% !important; }

.h-50 {
  height: 50% !important; }

.h-60 {
  height: 60% !important; }

.h-70 {
  height: 70% !important; }

.h-80 {
  height: 80% !important; }

.h-90 {
  height: 90% !important; }

.h-100 {
  height: 100% !important; }

.h-100-m {
  height: calc(100% - 1rem); }

.br-0 {
  border-radius: 0px; }

.br-1 {
  border-radius: 0.5rem; }

.br-2 {
  border-radius: 1rem; }

.br-3 {
  border-radius: 2rem; }

.br-4 {
  border-radius: 3rem; }

.br-5 {
  border-radius: 100rem; }

.min-w-none {
  min-width: unset !important; }

.max-w-none {
  max-width: unset !important; }

.min-h-none {
  min-height: unset !important; }

.max-h-none {
  max-height: unset !important; }

.show__under-mobile {
  display: none !important; }
  @media (max-width: 570px) {
    .show__under-mobile {
      display: block !important; } }

.show__under-tablet {
  display: none !important; }
  @media (max-width: 758px) {
    .show__under-tablet {
      display: block !important; } }

.show__under-desktop {
  display: none !important; }
  @media (max-width: 1200px) {
    .show__under-desktop {
      display: block !important; } }

.show__only-labtop {
  display: none !important; }
  @media (min-width: 1024px) and (max-width: 1700px) {
    .show__only-labtop {
      display: block !important; } }

.show__over-mobile {
  display: block !important; }
  @media (max-width: 570px) {
    .show__over-mobile {
      display: none !important; } }

.mini-box {
  border-radius: 5px;
  box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18);
  padding: 1rem;
  background-color: white; }

.shadow-none {
  box-shadow: none !important; }

.shadow-big {
  box-shadow: 0rem 0.47rem 2.19rem rgba(8, 10, 37, 0.03), 0rem 0.94rem 1.41rem rgba(8, 10, 37, 0.03), 0rem 0.25rem 0.53rem rgba(8, 10, 37, 0.05), 0rem 0.13rem 0.19rem rgba(8, 10, 37, 0.03) !important; }

.shadow-small {
  box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18) !important; }

.card {
  border: 1px solid rgba(32, 39, 140, 0.125);
  border-radius: 5px !important;
  box-shadow: 0rem 0.47rem 2.19rem rgba(8, 10, 37, 0.03), 0rem 0.94rem 1.41rem rgba(8, 10, 37, 0.03), 0rem 0.25rem 0.53rem rgba(8, 10, 37, 0.05), 0rem 0.13rem 0.19rem rgba(8, 10, 37, 0.03);
  margin-bottom: 1rem;
  position: relative;
  word-wrap: break-word;
  transition: all 0.2s;
  opacity: 1; }
  .card .card-tab {
    margin-bottom: 0;
    display: flex;
    height: 3rem;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
    background-color: #3e66fb; }
    @media (max-width: 570px) {
      .card .card-tab ul {
        width: 100%;
        padding: .5rem .3rem 0 .3rem !important; }
        .card .card-tab ul li {
          flex: 1 1 auto;
          padding: 0 !important; }
          .card .card-tab ul li a {
            line-height: auto;
            font-size: 0.8rem;
            font-weight: 700; } }
    .card .card-tab ul {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      margin: 0;
      padding-top: .5rem; }
      .card .card-tab ul li {
        box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18);
        line-height: 2.5rem;
        border-radius: .8rem .8rem 0 0;
        background-color: #d8e0fe;
        transition: all 0.2s; }
        .card .card-tab ul li a {
          display: block;
          padding: 0 1rem;
          text-align: center;
          line-height: auto;
          font-size: 0.9rem;
          font-weight: 700;
          white-space: nowrap;
          color: white; }
      .card .card-tab ul li + li {
        margin-left: .3rem; }
      .card .card-tab ul li.on {
        transition: all 0.2s;
        background-color: white; }
        .card .card-tab ul li.on a {
          color: #3252c9; }
  .card .card-header__tab {
    padding: 0 1rem; }
    .card .card-header__tab ul {
      display: flex;
      gap: .3rem;
      margin: 0;
      padding: 0; }
      .card .card-header__tab ul li a {
        display: block;
        min-height: 3.5rem;
        line-height: 3.5rem;
        padding: 0 1rem;
        text-align: center;
        line-height: auto;
        font-size: 0.8rem;
        font-weight: 400;
        white-space: nowrap;
        color: rgba(0, 0, 0, 0.85);
        text-transform: initial;
        position: relative; }
        .card .card-header__tab ul li a::after {
          opacity: 0;
          transition: all 0.2s;
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 4px;
          border-radius: 10rem;
          background-color: #3252c9; }
      .card .card-header__tab ul li.on a {
        color: #3252c9; }
        .card .card-header__tab ul li.on a::after {
          opacity: 1;
          transition: all 0.2s; }
  .card .card-header {
    min-height: 3.5rem;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eceaf2;
    border-radius: 5px 5px 0 0;
    padding: 0; }
    .card .card-header .card-header__title {
      min-height: 3.5rem;
      flex: 1;
      display: flex;
      align-items: center;
      gap: .58rem;
      overflow: hidden;
      padding-left: 1.25rem; }
      .card .card-header .card-header__title span {
        opacity: .5; }
      .card .card-header .card-header__title span + .title-wrap {
        width: calc(100% - 1rem); }
      .card .card-header .card-header__title .title-wrap {
        width: 100%;
        text-align: left; }
        .card .card-header .card-header__title .title-wrap * {
          margin: 0;
          padding: .05rem 0;
          width: calc(100% - 1rem);
          text-transform: capitalize;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
        .card .card-header .card-header__title .title-wrap .title__main {
          line-height: auto;
          font-size: 0.9rem;
          font-weight: 700;
          opacity: 1; }
        .card .card-header .card-header__title .title-wrap .title__sub {
          line-height: auto;
          font-size: 0.7rem;
          font-weight: 400;
          opacity: .7; }
    .card .card-header .card-header__function {
      padding: 0 1.25rem;
      min-height: 3.5rem;
      display: flex;
      align-items: center;
      align-content: center;
      text-align: center;
      justify-content: flex-end;
      gap: 5px; }
      .card .card-header .card-header__function .card-overwrap-btn {
        padding: 0;
        opacity: 20%; }
    @media (max-width: 570px) {
      .card .card-header {
        flex-wrap: wrap;
        min-height: unset !important; }
        .card .card-header .card-header__title {
          width: 100%; }
        .card .card-header .card-header__function {
          width: 100%;
          border-top: 1px solid #eceaf2;
          background-color: rgba(0, 0, 0, 0.03); }
          .card .card-header .card-header__function button {
            width: 100%; }
          .card .card-header .card-header__function .card-overwrap-btn {
            display: none; } }
  .card .card-body {
    padding: 1.25rem;
    overflow: auto;
    line-height: auto;
    font-size: 0.9rem;
    font-weight: 400; }
    .card .card-body .card-title {
      text-transform: uppercase;
      color: rgba(18, 21, 78, 0.7);
      font-weight: 700;
      font-size: .88rem;
      margin-bottom: .75rem; }
    .card .card-body .card-error {
      color: #ff3333;
      line-height: auto;
      font-size: 0.9rem;
      font-weight: 700; }
      .card .card-body .card-error::before {
        content: '!';
        font-weight: 900;
        margin-right: .3rem;
        width: 1rem;
        height: 100%; }
  .card .card-button {
    padding: .8rem 1rem;
    display: flex;
    justify-content: flex-end;
    gap: 5px; }
    .card .card-button button {
      min-width: 4rem; }
    .card .card-button .btn-cancle {
      color: #3e66fb; }
  .card.card-danger .card-header .card-header__title .title__main {
    line-height: auto;
    font-size: 1.2rem;
    font-weight: 700;
    color: #ff3333 !important; }
    .card.card-danger .card-header .card-header__title .title__main::before {
      content: '⚠';
      padding-right: .3rem; }
  .card.card-folded .card-body {
    transition: all .4s;
    padding: 0;
    max-height: 0 !important;
    overflow: hidden;
    opacity: 0; }
  .card .card-overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 1rem; }
    .card .card-overlay.on {
      display: block; }
    .card .card-overlay .card {
      background-color: white !important; }
      .card .card-overlay .card * {
        color: black !important; }
      .card .card-overlay .card .card-header {
        background-color: rgba(0, 0, 0, 0.1) !important; }

.tree-box {
  border: 1px solid lightgray; }
  .tree-box .tree-box__btn-box {
    padding: .5rem;
    border-bottom: 1px solid lightgray;
    margin-bottom: .5rem;
    text-align: right; }

.color-box {
  position: absolute;
  display: inline-block;
  top: 10px;
  right: 5px;
  width: 20px;
  height: 20px;
  margin-right: .5rem; }
  .color-box::after {
    content: '';
    position: absolute;
    right: 30px;
    width: 1px;
    height: 100%;
    background-color: #cac8cf; }

.move-box--group {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%; }
  .move-box--group .move-box {
    border: 1px solid #eceaf2;
    border-radius: 5px;
    width: 50%;
    height: 13.8rem;
    overflow: auto; }
    .move-box--group .move-box .move-box__title {
      padding: .3rem .5rem;
      border-bottom: 1px solid #eceaf2;
      background-color: #f6f6f9;
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .move-box--group .move-box .move-box__list {
      margin: 0;
      padding: 1rem .5rem;
      display: flex;
      flex-direction: column;
      gap: 5px; }
    @media (min-width: 570px) and (max-width: 758px) {
      .move-box--group .move-box .name {
        max-width: 5rem;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        vertical-align: middle;
        text-overflow: ellipsis; } }
  @media (max-width: 570px) {
    .move-box--group {
      flex-direction: column; }
      .move-box--group .move-box {
        width: 100%; }
      .move-box--group > span {
        transform: rotate(90deg);
        margin-top: .5rem; } }

.pnt-map-box {
  background-color: #818AAF;
  width: 100%;
  height: 100%;
  position: relative; }
  .pnt-map-box .map__function {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: .5rem; }
    .pnt-map-box .map__function .map__function--top {
      display: flex;
      gap: 5px; }
      .pnt-map-box .map__function .map__function--top > div {
        padding: .3rem;
        background-color: white !important;
        color: black !important;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        border: 1px solid rgba(32, 39, 140, 0.125);
        border-radius: .25rem;
        margin-bottom: 1rem;
        align-items: center;
        line-height: auto;
        font-size: 0.8rem;
        font-weight: 400; }
    .pnt-map-box .map__function .map__function--bottom {
      display: inline-flex;
      flex-direction: column;
      position: absolute;
      bottom: .5rem;
      right: .5rem; }
      .pnt-map-box .map__function .map__function--bottom button {
        background-color: white !important; }
        .pnt-map-box .map__function .map__function--bottom button:hover {
          filter: none !important;
          background-color: #f6f6f9 !important; }
  .pnt-map-box .map__pin {
    position: absolute; }
    .pnt-map-box .map__pin .map__pin--balloon {
      display: none;
      z-index: 10;
      background-color: white;
      box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18);
      border-radius: 5px;
      padding: .5rem;
      position: absolute;
      top: -170px;
      width: 150px;
      transition: all .3s; }
      .pnt-map-box .map__pin .map__pin--balloon::after {
        content: '';
        display: block;
        position: absolute;
        bottom: -15px;
        left: 20px;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: #ffffff transparent transparent transparent; }
      .pnt-map-box .map__pin .map__pin--balloon .btn-view {
        width: 100%;
        min-height: 1rem;
        padding: .5rem; }
    .pnt-map-box .map__pin .icon-gps-fill {
      margin: 1.2rem 0 0 20px;
      transform: scale(2); }
    .pnt-map-box .map__pin.on .icon-gps {
      background-color: #FFC107;
      box-shadow: 0px 0px 20px #FFC107;
      border-radius: 100px;
      transition: all .3s; }
    .pnt-map-box .map__pin.active {
      transition: all .3s; }
      .pnt-map-box .map__pin.active .icon-gps {
        background-color: #FF7800;
        box-shadow: 0px 0px 20px #FF7800;
        border-radius: 100px; }
      .pnt-map-box .map__pin.active .map__pin--balloon {
        display: block; }
  .pnt-map-box .map__img {
    width: 100%;
    height: 100%;
    overflow: hidden; }
    .pnt-map-box .map__img img {
      width: 100%; }
