.app-header__logo .logo-src {
    width: 112px;
    background-size: contain;
}

.app-main .app-main__inner {
    padding: 10px;
    // 내보내기 버튼
    .btn-dashboard-export {
        position: fixed;
        bottom: 112px;
        right: 13px;
        opacity: 0.7;
        width: 82px;
    }
    .btn-dashboard-fullscreen {
        position: fixed;
        // top: 70px;
        // right: 13px;
        bottom: 75px;
        right: 13px;
        opacity: 0.7;
        width: 82px;
    }
    .btn-dashboard-edit {
        position: fixed;
        bottom: 37px;
        right: 13px;
        opacity: 0.7;
        width: 82px;
    }

    &:fullscreen {
        .btn-dashboard-fullscreen {
            top: 15px;
            right: 15px;
        }
        .btn-dashboard-edit {
            bottom: 37px;
            right: 15px;
        }
    }
}

.app-page-title {
    padding: 20px;
    margin: -10px -10px 10px;
}

.closed-sidebar .app-sidebar .vertical-nav-menu .metismenu-container .metismenu-container.visible > .metismenu-item > div {
    border-bottom: none;
}

.metismenu.vertical-nav-menu>.metismenu-container>.metismenu-item>.metismenu-link>span {
    font-weight: bold;
}
.metismenu.vertical-nav-menu>.metismenu-container>.metismenu-item>.metismenu-link *>span {
    font-weight: 400;
}
.vertical-nav-menu .metismenu-container .metismenu-container.visible > .metismenu-item > .metismenu-link.active,
.vertical-nav-menu .metismenu-container .metismenu-container.visible > .metismenu-item > div > .metismenu-link.active {
    color: var(--font-black);
    background: var(--blur-blue);
}

.vertical-nav-menu .metismenu-container .metismenu-link:hover {
    color: var(--font-black) !important;
    background: var(--blur-blue);
}
.vertical-nav-menu .metismenu-container .metismenu-container.visible:before {
    background: var(--blur-blue);
}

.vertical-nav-menu
    .metismenu-container
    .metismenu-container.visible
    > .metismenu-item
    .metismenu-link.last-custom-dashboard-link,
.vertical-nav-menu .metismenu-container .metismenu-container.visible > .metismenu-item > div .metismenu-link {
    height: 2.3em;
    line-height: 2.3em;
    color: #6c757d;
    margin: 0 0 3px;
}
.vertical-nav-menu
    .metismenu-container
    .metismenu-container
    .metismenu-item
    .metismenu-link.last-custom-dashboard-link {
    height: 0;
    overflow: hidden;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    // Smart Military Custom
    //background-color: var(--org);
    background-color: var(--secondary);
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
    // Smart Military Custom
    //background-color: var(--org);
    background-color: var(--secondary);
}

.menu-title{
    font-weight: bold;
    color: #9c9ca3;
}

.sub-menu-title{
    font-weight: bold;
    color: #22388a;
    margin-left: 0.3rem;
}
/*body {*/
/*    overflow: hidden;*/
/*    margin: 0;*/
/*    height: 100%;*/
/*    padding-right: 0px !important;*/
/*}*/
/*#root {*/
/*    overflow: auto;*/
/*    height: 100%;*/
/*}*/
/*.wrapClassName {*/
/*    overflow: hidden;*/
/*    position: fixed;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    background-color: rgba(0, 0, 0, .6);*/
/*}*/
/*#root::-webkit-scrollbar-track {*/
/*    z-index: 9999;*/
/*    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
/*    background-color: #F5F5F5;*/
/*}*/
/*#root::-webkit-scrollbar {*/
/*    z-index: 9999;*/
/*    width: 10px;*/
/*    background-color: #F5F5F5;*/
/*}*/
/*#root::-webkit-scrollbar-thumb {*/
/*    z-index: 9999;*/
/*    background-color: #4a4a4a;*/
/*    border: 1px solid #e9ecef;*/
/*    border-radius: 3px;*/
/*}*/
