//Menu
.select-menu {
    background-color: white;
    border-radius: 2px;
    box-shadow: 0 0 0 1px hsla(218, 50%, 10%, 0.1), 0 4px 11px hsla(218, 50%, 10%, 0.1);
    margin-top: 1px;
    position: absolute;
    z-index: 3;
    min-width: 100%;
    max-width: 14rem;
}

.align-right .select-menu {
    left: 100%;
    transform: translateX(-100%);
}

//Blanket
.select-blanket {
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    position: fixed;
    z-index: 1;
}

//Dropdown
.select-dropdown {
    position: relative;
    font-size: 0.8rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

//Dropdown Indicator
.select-dropdown-indicator {
    color: gray;
    height: 24px;
    width: 32px;
}

.styled-option {
    padding: 4px 8px;
    &:hover,
    &:focus,
    &:active {
        background-color: #ebebeb;
    }
    & > label {
        margin-bottom: 0;
    }
}

.styled-option-label {
    padding: 4px 8px;
    text-align: left;
    color: #999999;
}

#select-container,
#modal-select-container {
    // Options container
    & .react-select__menu {
        width: 100%;
        padding: 0;
        margin: 0;
        //border: 1px solid $form-border-color;
        border-radius: 0 0 $form-radius $form-radius;
        border: 1px solid #6e6e86;
        box-shadow: $form-shadow;
        overflow-y: auto;
        background-color: white;

        & .react-select__menu-list {
            font-size: $font-size-base;
            border-radius: 0 !important;

            // Option item
            & .react-select__option {
                max-width: 100%;
                min-height: $form-height;
                padding: 0.4rem 0.5rem;
                border-bottom: 1px solid $form-border-color !important;
                background-color: transparent;
                cursor: pointer;
                white-space: nowrap;
                overflow: hidden;
                text-transform: capitalize;
                text-overflow: ellipsis !important;

                &:last-child {
                    border: none !important;
                }
                &:hover {
                    color: $form-txt;
                    background-color: $gray-700 !important;
                    transition: $form-transition;
                }

                // Option item selected
                &.react-select__option--is-selected {
                    background-color: $gray-300;
                    color: $default-secondary;
                }
            }
        }

        //ul 드롭다운 부분
        & .select__options {
            width: 100%;
            padding: 0;
            margin: 0;
            border-radius: $form-radius;
            box-shadow: $form-shadow;
            & .select__options-list {
                margin: 0;
                padding: 0;
            }
            & .select__option {
                min-height: $form-height;
                display: flex;
                align-items: center;
                line-height: normal;
                border-radius: $form-radius;
                background-color: transparent;
                text-transform: capitalize;
                cursor: pointer;

                &:hover {
                    background-color: $gray-100;
                    transition: $form-transition;
                }
                &.on {
                    background-color: $gray-300;
                    color: $default-secondary;
                }
            }
        }
    }
}

// React-select
.react-select {
    position: relative;
    width: 9rem;

    @media (max-width: $breakpoint-mobile) {
        width: 100%;
        max-width: unset;
    }

    // Control
    & .react-select__control {
        position: relative;
        width: 100%;
        height: $form-height;
        min-height: unset;
        //border-radius: $form-radius;
        &.form-h-small {
            height: $form-height-small;
        }
        &.form-h-big {
            height: $form-height-big;
        }
        @media (max-width: $breakpoint-mobile) {
            width: 100%;
            max-width: unset;
        }

        & .select__btn {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: $form-bg-color;
            padding: 0.5rem;
            padding-left: $form-left-padding;
            position: relative;
            border: 1px solid $form-border-color;
            border-radius: $form-radius;

            & .cont-wrap {
                display: flex;
                align-items: center;
                width: 100%;
                padding-right: 1.5rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-align: left;

                & > span {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    text-align: left;
                }

                // Control icon
                & .material-icons-round {
                    margin-right: 5px;
                    font-size: 18px;
                    display: inline-block;
                    vertical-align: middle;
                }

                // Control value container
                & .react-select__value-container {
                    &.react-select__value-container--has-value {
                    }

                    // Single value
                    & .react-select__single-value {
                        //font-weight: 600;
                    }
                }
            }

            // Indicators
            & .react-select__indicators {
                // Dropdown icon
                & > .material-icons-round {
                    position: absolute;
                    right: 0;
                    transform: translateX(3px);
                }
            } // End Indicators
        }

        &.react-select__control--menu-is-open {
            box-shadow: $form-shadow;
            border-radius: $form-radius;
            & .select__btn {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &.react-select__control--is-disabled.react-select__control {
            opacity: 0.5;
        }
    } // End Control

    // Options container
    & .react-select__menu {
        width: 100%;
        padding: 0;
        margin: 0;
        border: 1px solid $form-border-color;
        border-top: none;
        border-radius: 0 0 $form-radius $form-radius;
        box-shadow: $form-shadow;
        overflow-y: auto;
        background-color: white;

        & .react-select__menu-list {
            font-size: $font-size-base;
            border-radius: 0 !important;

            // Option item
            & .react-select__option {
                max-width: 100%;
                min-height: $form-height;
                padding: 0.4rem 0.5rem;
                border-bottom: 1px solid $form-border-color !important;
                background-color: transparent;
                cursor: pointer;
                white-space: nowrap;
                overflow: hidden;
                text-transform: capitalize;
                text-overflow: ellipsis !important;

                &:last-child {
                    border: none !important;
                }
                &:hover {
                    color: $form-txt;
                    background-color: $gray-100 !important;
                    transition: $form-transition;
                }

                // Option item selected
                &.react-select__option--is-selected {
                    background-color: $gray-300;
                    color: $default-secondary;
                }
            }
        }
    }

    //옵션
    &.icon-on-left {
        width: 9rem;
    }

    &.select-icon-only {
        width: 44px;
        min-width: unset;
        & .react-select__control {
            & .select__btn {
                padding: 0.3rem;

                & .cont-wrap {
                    overflow: visible;
                }
            }
        }
        & .react-select__menu {
            width: 10rem;
            top: calc(100% - 1px);
            left: 50%;
            transform: translateX(-50%);
            border: 1px solid $form-border-color;
            border-radius: $form-radius;
        }
    }

    //사이즈
    &.form-h-small {
        & .select__btn {
            & .cont-wrap > .material-icons-round {
                font-size: 14px;
            }

            &.select-icon-only {
                width: 36px;
            }
        }
    }
    &.form-h-big {
        & .select__btn {
            & .cont-wrap > .material-icons-round {
                font-size: 26px;
            }

            & .select__btn > .material-icons-round {
                font-size: 36px;
            }

            &.select-icon-only {
                width: 60px;
            }

            & .select__options li a {
                padding: 1.5rem 0.5rem;
            }
        }
    }
}
